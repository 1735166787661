
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import LandingLabel from '@/components/landingAndRegistrationComponents/LandingLabel.vue';

@Component({
  components: { BaseButton, LandingLabel },
})
export default class LandingHero extends Vue {
  @Prop({ default: '' }) title!: string;
  @Prop({ default: '' }) description!: string;
  @Prop({ default: '' }) buttonText!: string;
  @Prop({ default: '' }) imgFolder!: string;
  @Prop({ default: '' }) labelFolder!: string;
  @Prop({ default: '' }) labelText!: string;
  @Prop({ default: '#fff' }) topShadowColor!: string;
  @Prop({ default: '#fff' }) bottomShadowColor!: string;
  @Prop({ default: '470px' }) imgMaxWidth!: number;
  @Prop({ default: false }) reversed!: boolean;
  @Prop({ default: false }) isNextBtnMobileBottom!: boolean;

  heroOrder(): string | null{
    return this.reversed ? 'reversed' : null;
  }

  heroNextButtonMobileType(): string | null {
    return this.isNextBtnMobileBottom ? 'next-button-mobile-bottom' : null;
  }

  heroClasses(): any[] {
    return [this.heroOrder(), this.heroNextButtonMobileType()];
  }
}
